import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, Input, HostListener, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/cdk';
import { tuiIsString, TuiValidationError, TuiLetModule } from '@taiga-ui/cdk';
import { tuiHeightCollapse, tuiFadeIn } from '@taiga-ui/core/animations';
import { MODE_PROVIDER } from '@taiga-ui/core/providers';
import { TUI_ANIMATION_OPTIONS, TUI_MODE, TUI_DEFAULT_ERROR_MESSAGE } from '@taiga-ui/core/tokens';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i4 from 'rxjs';
const _c0 = () => ({});
function TuiErrorComponent_ng_container_0_div_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r1, " ");
  }
}
function TuiErrorComponent_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵtemplate(2, TuiErrorComponent_ng_container_0_div_1_ng_container_2_Template, 2, 1, "ng-container", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const defaultErrorMessage_r2 = i0.ɵɵnextContext().tuiLet;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-message-text_light", i0.ɵɵpipeBind1(1, 6, ctx_r2.mode$) === "onDark");
    i0.ɵɵproperty("@tuiFadeIn", ctx_r2.animation)("@tuiHeightCollapse", ctx_r2.animation);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.error.message || defaultErrorMessage_r2)("polymorpheusOutletContext", ctx_r2.error.context || i0.ɵɵpureFunction0(8, _c0));
  }
}
function TuiErrorComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiErrorComponent_ng_container_0_div_1_Template, 3, 9, "div", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.error && ctx_r2.visible);
  }
}
class TuiErrorComponent {
  constructor(animation, mode$, defaultErrorMessage$) {
    this.animation = animation;
    this.mode$ = mode$;
    this.defaultErrorMessage$ = defaultErrorMessage$;
    this.error = null;
    this.visible = true;
  }
  set errorSetter(error) {
    this.error = tuiIsString(error) ? new TuiValidationError(error) : error;
  }
  onAnimation(visible) {
    this.visible = visible;
  }
}
TuiErrorComponent.ɵfac = function TuiErrorComponent_Factory(t) {
  return new (t || TuiErrorComponent)(i0.ɵɵdirectiveInject(TUI_ANIMATION_OPTIONS), i0.ɵɵdirectiveInject(TUI_MODE), i0.ɵɵdirectiveInject(TUI_DEFAULT_ERROR_MESSAGE));
};
TuiErrorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiErrorComponent,
  selectors: [["tui-error"]],
  hostBindings: function TuiErrorComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("animationcancel.self", function TuiErrorComponent_animationcancel_self_HostBindingHandler() {
        return ctx.onAnimation(false);
      })("animationstart.self", function TuiErrorComponent_animationstart_self_HostBindingHandler() {
        return ctx.onAnimation(true);
      });
    }
  },
  inputs: {
    errorSetter: [i0.ɵɵInputFlags.None, "error", "errorSetter"]
  },
  features: [i0.ɵɵProvidersFeature([MODE_PROVIDER])],
  decls: 2,
  vars: 3,
  consts: [[4, "tuiLet"], ["automation-id", "tui-error__text", "class", "t-message-text", 3, "t-message-text_light", 4, "ngIf"], ["automation-id", "tui-error__text", 1, "t-message-text"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"]],
  template: function TuiErrorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiErrorComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(1, 1, ctx.defaultErrorMessage$));
    }
  },
  dependencies: [i1.TuiLetDirective, i2.NgIf, i3.PolymorpheusOutletDirective, i2.AsyncPipe],
  styles: ["[_nghost-%COMP%]{font:var(--tui-font-text-s);color:var(--tui-text-01);display:block;color:var(--tui-negative);word-wrap:break-word;animation:tuiPresent 1s infinite}.t-message-text[_ngcontent-%COMP%]{margin-top:.25rem;white-space:pre-line}.t-message-text_light[_ngcontent-%COMP%]{color:var(--tui-negative-night)}"],
  data: {
    animation: [tuiHeightCollapse, tuiFadeIn]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiErrorComponent, [{
    type: Component,
    args: [{
      selector: 'tui-error',
      templateUrl: './error.template.html',
      styleUrls: ['./error.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [MODE_PROVIDER],
      animations: [tuiHeightCollapse, tuiFadeIn]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ANIMATION_OPTIONS]
      }]
    }, {
      type: i4.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_MODE]
      }]
    }, {
      type: i4.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_DEFAULT_ERROR_MESSAGE]
      }]
    }];
  }, {
    errorSetter: [{
      type: Input,
      args: ['error']
    }],
    onAnimation: [{
      type: HostListener,
      args: ['animationcancel.self', ['false']]
    }, {
      type: HostListener,
      args: ['animationstart.self', ['true']]
    }]
  });
})();
class TuiErrorModule {}
TuiErrorModule.ɵfac = function TuiErrorModule_Factory(t) {
  return new (t || TuiErrorModule)();
};
TuiErrorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiErrorModule
});
TuiErrorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule, TuiLetModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiErrorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiLetModule],
      declarations: [TuiErrorComponent],
      exports: [TuiErrorComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiErrorComponent, TuiErrorModule };
