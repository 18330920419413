import * as i0 from '@angular/core';
import { Pipe, Optional, SkipSelf, Inject, Self, Injector, NgModule } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { __decorate } from 'tslib';
import * as i1 from '@angular/forms';
import { NgControl, ControlContainer } from '@angular/forms';
import { TuiValidationError, tuiIsString, tuiPure } from '@taiga-ui/cdk';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit/tokens';
import { of, isObservable } from 'rxjs';
import { map } from 'rxjs/operators';
const EMPTY_RECORD = {};
function unwrapObservable(content, context) {
  return content.pipe(map(error => new TuiValidationError(error || '', context)));
}
function defaultError(content, context) {
  return of(new TuiValidationError(content || '', context));
}
class TuiFieldErrorPipe {
  constructor(parent, self, container, validationErrors) {
    this.parent = parent;
    this.self = self;
    this.container = container;
    this.validationErrors = validationErrors;
    this.order = [];
    if (this.self && !this.self.valueAccessor) {
      this.self.valueAccessor = this;
    }
  }
  transform(order) {
    this.order = order;
    return this.computedError;
  }
  get computedError() {
    return this.invalid && this.touched && this.error || of(null);
  }
  registerOnChange() {}
  registerOnTouched() {}
  setDisabledState() {}
  writeValue() {}
  get error() {
    const {
      errorId
    } = this;
    if (!errorId) {
      return null;
    }
    const firstError = this.controlErrors[errorId];
    const errorContent = this.validationErrors[errorId];
    return this.getError(firstError, errorContent);
  }
  get invalid() {
    var _a;
    return !!((_a = this.control) === null || _a === void 0 ? void 0 : _a.invalid);
  }
  get touched() {
    var _a;
    return !!((_a = this.control) === null || _a === void 0 ? void 0 : _a.touched);
  }
  get control() {
    var _a, _b, _c;
    return ((_a = this.self) === null || _a === void 0 ? void 0 : _a.control) || ((_b = this.parent) === null || _b === void 0 ? void 0 : _b.control) || ((_c = this.container) === null || _c === void 0 ? void 0 : _c.control);
  }
  get errorId() {
    return this.getErrorId(this.order, this.controlErrors);
  }
  get controlErrors() {
    var _a;
    return ((_a = this.control) === null || _a === void 0 ? void 0 : _a.errors) || EMPTY_RECORD;
  }
  getErrorId(order, controlErrors) {
    const id = order === null || order === void 0 ? void 0 : order.find(errorId => controlErrors[errorId]);
    const fallback = Object.keys(controlErrors)[0];
    return id || fallback || '';
  }
  getError(context, content) {
    if (context instanceof TuiValidationError) {
      return of(context);
    }
    if (content === undefined && tuiIsString(context)) {
      return of(new TuiValidationError(context));
    }
    if (isObservable(content)) {
      return unwrapObservable(content, context);
    }
    if (content instanceof Function) {
      const message = content(context);
      return isObservable(message) ? unwrapObservable(message, context) : defaultError(message, context);
    }
    return defaultError(content, context);
  }
}
TuiFieldErrorPipe.ɵfac = function TuiFieldErrorPipe_Factory(t) {
  return new (t || TuiFieldErrorPipe)(i0.ɵɵdirectiveInject(NgControl, 28), i0.ɵɵdirectiveInject(NgControl, 26), i0.ɵɵdirectiveInject(ControlContainer, 24), i0.ɵɵdirectiveInject(TUI_VALIDATION_ERRORS, 16));
};
TuiFieldErrorPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiFieldError",
  type: TuiFieldErrorPipe,
  pure: false
});
__decorate([tuiPure], TuiFieldErrorPipe.prototype, "getErrorId", null);
__decorate([tuiPure], TuiFieldErrorPipe.prototype, "getError", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFieldErrorPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiFieldError',
      pure: false
    }]
  }], function () {
    return [{
      type: i1.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i1.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i1.ControlContainer,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [ControlContainer]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_VALIDATION_ERRORS]
      }]
    }];
  }, {
    getErrorId: [],
    getError: []
  });
})();
class TuiFieldErrorContentPipe {
  constructor(injector) {
    this.injector = injector;
    this.localInjector = Injector.create({
      providers: [{
        provide: AsyncPipe
      }, {
        provide: TuiFieldErrorPipe
      }],
      parent: this.injector
    });
    this.asyncPipe = this.localInjector.get(AsyncPipe);
    this.fieldErrorPipe = this.localInjector.get(TuiFieldErrorPipe);
  }
  transform(order) {
    return this.getErrorContent(order);
  }
  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
  getErrorContent(order) {
    const error = this.asyncPipe.transform(this.fieldErrorPipe.transform(order));
    if (!error) {
      return '';
    }
    return typeof error.message === 'function' ? error.message(error.context) : error.message;
  }
}
TuiFieldErrorContentPipe.ɵfac = function TuiFieldErrorContentPipe_Factory(t) {
  return new (t || TuiFieldErrorContentPipe)(i0.ɵɵdirectiveInject(Injector, 16));
};
TuiFieldErrorContentPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiFieldErrorContent",
  type: TuiFieldErrorContentPipe,
  pure: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFieldErrorContentPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiFieldErrorContent',
      pure: false
    }]
  }], function () {
    return [{
      type: i0.Injector,
      decorators: [{
        type: Inject,
        args: [Injector]
      }]
    }];
  }, null);
})();
class TuiFieldErrorPipeModule {}
TuiFieldErrorPipeModule.ɵfac = function TuiFieldErrorPipeModule_Factory(t) {
  return new (t || TuiFieldErrorPipeModule)();
};
TuiFieldErrorPipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFieldErrorPipeModule
});
TuiFieldErrorPipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFieldErrorPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiFieldErrorPipe, TuiFieldErrorContentPipe],
      exports: [TuiFieldErrorPipe, TuiFieldErrorContentPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFieldErrorContentPipe, TuiFieldErrorPipe, TuiFieldErrorPipeModule };
